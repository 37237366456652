import { Fragment, type ReactNode } from 'react'
import { type NavigateFunction, useNavigate } from 'react-router-dom'

import { Autocomplete, Box, Divider, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { CompanyAvatar, Header, Text } from '@leaf/components'

import { graphqlClient } from '@/api'
import { DEFAULT_ROUTE } from '@/navigation/default-route'
import { GetShippersDocument } from '@/navigation/pages/shippers.api.generated'
import type { ShippersType } from '@/navigation/pages/types'
import { useStore } from '@/store'
import type { Title } from '@/types'

const buildBreadcrumbs = (titles: Title[], navigate: NavigateFunction) => {
  const crumbs = [] as ReactNode[]

  titles.forEach((title, i) => {
    if (title.to) {
      crumbs.push(
        <Fragment key={`${i}-title-wrapper`}>
          <Text.Caption
            key={`${i}-title`}
            onClick={() => navigate(title.to!)}
            sx={{ color: 'gray', cursor: 'pointer' }}
          >
            {title.label}
          </Text.Caption>
        </Fragment>,
      )
    } else {
      crumbs.push(<Text.Caption key={`${i}-title`}>{title.label}</Text.Caption>)
    }
    if (i < titles.length - 1) {
      crumbs.push(
        <Divider
          flexItem
          key={`${i}-divider`}
          orientation='vertical'
          sx={{ margin: '0 1em 0 1em' }}
        />,
      )
    }
  })
  return crumbs
}

type LayoutHeaderProps = {
  impersonation: boolean
}
const LayoutHeader = ({ impersonation }: LayoutHeaderProps) => {
  const [user, titles, updateUser] = useStore(
    useShallow((state) => [state.user, state.titles, state.updateUser]),
  )

  const { data: shippers, isLoading: isShippersLoading } = useQuery({
    enabled: impersonation,
    queryFn: async () => {
      const response = await graphqlClient.request(GetShippersDocument)
      return response.companies as ShippersType
    },
    queryKey: ['shippers'],
  })

  const navigate = useNavigate()

  const options = shippers?.length ? shippers : [{ id: user.companyId, name: '' }]
  const selection = options.find((s) => s.id === user.companyId)

  const onChange = (_: unknown, value: ShippersType[0] | null) => {
    if (!value) {
      throw new Error('Shipper could not be selected')
    }
    updateUser({ companyId: value.id })
    navigate(DEFAULT_ROUTE)
  }

  return (
    <Header>
      <Box sx={{ display: 'flex' }}>{buildBreadcrumbs(titles, navigate)}</Box>

      {user.isAdmin && !isShippersLoading && (
        <Autocomplete
          disableClearable
          fullWidth
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={onChange}
          options={options}
          renderInput={(params) => <TextField {...params} variant='standard' />}
          sx={{ width: '250px' }}
          value={selection}
        />
      )}

      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <CompanyAvatar id={user.companyId?.toString() ?? -10} />

        <Text.H3>{user.name}</Text.H3>
      </Box>
    </Header>
  )
}

export { LayoutHeader }
