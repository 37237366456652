import { useParams } from 'react-router-dom'

import { Box, Card, CardContent, Grid, Typography, styled } from '@mui/material'
import { DataGridPro, type GridColDef, type GridRowSpacingParams } from '@mui/x-data-grid-pro'
import { useQuery } from '@tanstack/react-query'

import { CardHeader, DataRow, Map, Page, Progress, Route, Tabs } from '@leaf/components'
import { useTabs } from '@leaf/hooks'
import { color, time } from '@leaf/utilities'

import {
  type AdaptNetworkMoveQueryReturnType,
  adaptNetworkMoveDetailsQuery,
} from '@/features/adapt-network-move/adapt-network-move-details.api'
import { AdaptNetworkMoveRouteScheduleLane } from '@/features/adapt-network-move/adapt-network-move-details-route-schedule-lane'
import { useTitles } from '@/hooks'

const Deadhead = styled(Box)`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 4em;
`

const laneColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: '#',
    type: 'number',
  },
  {
    field: 'annualLoads',
    headerName: 'Annual Loads',
    minWidth: 150,
    type: 'number',
  },
  {
    field: 'companyMiles',
    headerName: 'Miles',
    type: 'number',
  },
  {
    field: 'deadhead',
    headerName: 'Deadhead Miles',
    minWidth: 150,
    type: 'number',
  },
  {
    field: 'oMarket',
    headerName: 'Origin Market',
    minWidth: 150,
  },
  {
    field: 'dMarket',
    headerName: 'Destination Market',
    minWidth: 150,
  },
]

const AdaptNetworkMoveDetails = () => {
  const { id } = useParams()
  const { data } = useQuery(adaptNetworkMoveDetailsQuery(id!))
  const move = data as AdaptNetworkMoveQueryReturnType
  type MoveKey = keyof typeof move

  const { activeTab, setActiveTab } = useTabs()

  useTitles([{ label: 'Adapt Network Moves', to: 'adapt/network-moves' }, { label: id! }])

  const laneRows: object[] = []
  const shippers = []
  const fitBounds = []

  for (let i = 0; i < 5; i += 1) {
    const lane = move[`lane${i}` as MoveKey]
    if (!lane) {
      break
    }

    shippers.push(lane.company.id)
    fitBounds.push(lane.geometry)

    const deadhead = move[`lane${i + 1}` as MoveKey]
      ? move[`deadheadL${i}L${i + 1}` as MoveKey]
      : move.deadheadFinal

    laneRows.push({
      annualLoads: move[`l${i}AnnualLoads` as MoveKey] || '-',
      companyMiles: lane.companyMiles || '-',
      dMarket: move[`l${i}DMarket` as MoveKey] || '-',
      deadhead,
      id: i + 1,
      oMarket: move[`l${i}OMarket` as MoveKey] || '-',
    })
  }

  const nShippers = new Set(shippers).size

  return (
    <>
      <Progress />

      <Page id='e2e-adapt-network-moves-details'>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='General' />

            <CardContent>
              <Grid container spacing={12}>
                <Grid item xs={4}>
                  <DataRow label='Move Type' value={move.moveType} />

                  <DataRow label='Batch Date' value={time.toLocalDate(move.batchDate)} />

                  <DataRow label='Balance Type' value={move.balanceType} />

                  <DataRow label='# Shippers' value={nShippers} />
                </Grid>

                <Grid item xs={4}>
                  <DataRow label='Total Miles' value={move.totalMiles} />

                  <DataRow label='Origin Market' value={move.oMarket} />

                  <DataRow label='Destination Market' value={move.dMarket} />

                  <DataRow label='Days' value={move.days} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Tabs activeTab={activeTab} changeTab={setActiveTab} tabs={['Map', 'Lanes']} />

            <CardContent sx={{ padding: 0 }}>
              <Grid container>
                <Grid item sx={{ marginLeft: '3em', marginRight: '2em' }} xs={3}>
                  <Box sx={{ height: '100%', minWidth: '100%' }}>
                    {[0, 1, 2, 3, 4].map((i) => {
                      const lane = move[`lane${i}` as MoveKey]

                      if (!lane) {
                        return null
                      }
                      const deadhead = move[`lane${i + 1}` as MoveKey]
                        ? move[`deadheadL${i}L${i + 1}` as MoveKey]
                        : move.deadheadFinal

                      return (
                        <>
                          <AdaptNetworkMoveRouteScheduleLane
                            color={color.lane(i)}
                            data={lane}
                            position={i * 2 + 1}
                          />

                          <Deadhead>
                            {[null, undefined].includes(deadhead) || (
                              <Typography variant='caption'>{deadhead} miles</Typography>
                            )}
                          </Deadhead>
                        </>
                      )
                    })}
                  </Box>
                </Grid>

                <Grid item sx={{ width: '100vw' }} xs={8}>
                  {activeTab === 0 && (
                    <Box sx={{ height: 'calc(100% + 3em)', width: 'calc(100% + 3em)' }}>
                      <Map
                        fitBounds={fitBounds}
                        mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
                      >
                        {[0, 1, 2, 3, 4].map(
                          (i) =>
                            move[`lane${i}` as MoveKey] && (
                              <Route.MapLane
                                color={color.lane(i)}
                                geometry={move[`lane${i}` as MoveKey].geometry}
                                id={move[`lane${i}` as MoveKey].id}
                                position={i * 2}
                              />
                            ),
                        )}
                      </Map>
                    </Box>
                  )}

                  {activeTab === 1 && (
                    <DataGridPro
                      columns={laneColumns}
                      disableColumnMenu
                      disableColumnSorting
                      getRowSpacing={(params: GridRowSpacingParams) => {
                        return {
                          bottom: move[`lane${params.id}` as MoveKey] ? 160 : 0,
                          top: params.id === 1 ? 10 : 0,
                        }
                      }}
                      hideFooterRowCount
                      rowSelection={false}
                      rows={laneRows}
                      sx={{
                        '& .MuiDataGrid-row.Mui-hovered': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiDataGrid-row:hover': {
                          backgroundColor: 'transparent',
                        },
                        'width': 'calc(100% + 3em)',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Page>
    </>
  )
}

export { AdaptNetworkMoveDetails }
