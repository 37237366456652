// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetConstellationQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetConstellationQuery = { __typename?: 'query_root', constellationsByPk?: { __typename?: 'constellations', id: any, name: string, constellationLanes: Array<{ __typename?: 'constellationLanes', equipmentClass: string, batchDate: any, annualizedLoads?: number, lane: { __typename?: 'analyticsLanes', id: any, companyId: any, leafMiles?: any, geometry: any, originLocation?: { __typename?: 'analyticsLocations', name: string }, destinationLocation?: { __typename?: 'analyticsLocations', name: string } } }> } };

export type GetConstellationNetworkMovesQueryVariables = Types.Exact<{
  constellationId: Types.Scalars['uuid']['input'];
}>;


export type GetConstellationNetworkMovesQuery = { __typename?: 'query_root', sortedConstellationNetworkMoves: Array<{ __typename?: 'sortedConstellationNetworkMove', constellation_id: any, network_move_id: any, move_type?: string, l0_id: any, l1_id: any, l2_id?: any, l3_id?: any, l4_id?: any, l0_shipper_id: any, l1_shipper_id: any, l2_shipper_id: any, l3_shipper_id?: any, l4_shipper_id?: any, l0_shipper_name: string, l1_shipper_name: string, l2_shipper_name?: string, l3_shipper_name?: string, l4_shipper_name?: string, l0_loads?: number, l1_loads?: number, l2_loads?: number, l3_loads?: any, l4_loads?: any, l0_miles?: any, l1_miles?: any, l2_miles?: any, l3_miles?: any, l4_miles?: any, l0_geometry: any, l1_geometry: any, l2_geometry: any, l3_geometry?: any, l4_geometry?: any, l0_origin: string, l1_origin: string, l2_origin: string, l3_origin?: string, l4_origin?: string, l0_destination: string, l1_destination: string, l2_destination: string, l3_destination?: string, l4_destination?: string }> };



export const GetConstellationDocument = `
    query GetConstellation($id: uuid!) {
  constellationsByPk(id: $id) {
    id
    name
    constellationLanes {
      equipmentClass
      batchDate
      annualizedLoads
      lane {
        id
        companyId
        leafMiles
        originLocation {
          name
        }
        destinationLocation {
          name
        }
        geometry
      }
    }
  }
}
    `;

export const useGetConstellationQuery = <
      TData = GetConstellationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetConstellationQueryVariables,
      options?: UseQueryOptions<GetConstellationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetConstellationQuery, TError, TData>(
      ['GetConstellation', variables],
      fetcher<GetConstellationQuery, GetConstellationQueryVariables>(client, GetConstellationDocument, variables, headers),
      options
    )};

useGetConstellationQuery.document = GetConstellationDocument;

useGetConstellationQuery.getKey = (variables: GetConstellationQueryVariables) => ['GetConstellation', variables];


useGetConstellationQuery.fetcher = (client: GraphQLClient, variables: GetConstellationQueryVariables, headers?: RequestInit['headers']) => fetcher<GetConstellationQuery, GetConstellationQueryVariables>(client, GetConstellationDocument, variables, headers);

export const GetConstellationNetworkMovesDocument = `
    query GetConstellationNetworkMoves($constellationId: uuid!) {
  sortedConstellationNetworkMoves(args: {constellationId: $constellationId}) {
    constellation_id
    network_move_id
    move_type
    l0_id
    l1_id
    l2_id
    l3_id
    l4_id
    l0_shipper_id
    l1_shipper_id
    l2_shipper_id
    l3_shipper_id
    l4_shipper_id
    l0_shipper_name
    l1_shipper_name
    l2_shipper_name
    l3_shipper_name
    l4_shipper_name
    l0_loads
    l1_loads
    l2_loads
    l3_loads
    l4_loads
    l0_miles
    l1_miles
    l2_miles
    l3_miles
    l4_miles
    l0_geometry
    l1_geometry
    l2_geometry
    l3_geometry
    l4_geometry
    l0_origin
    l1_origin
    l2_origin
    l3_origin
    l4_origin
    l0_destination
    l1_destination
    l2_destination
    l3_destination
    l4_destination
  }
}
    `;

export const useGetConstellationNetworkMovesQuery = <
      TData = GetConstellationNetworkMovesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetConstellationNetworkMovesQueryVariables,
      options?: UseQueryOptions<GetConstellationNetworkMovesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetConstellationNetworkMovesQuery, TError, TData>(
      ['GetConstellationNetworkMoves', variables],
      fetcher<GetConstellationNetworkMovesQuery, GetConstellationNetworkMovesQueryVariables>(client, GetConstellationNetworkMovesDocument, variables, headers),
      options
    )};

useGetConstellationNetworkMovesQuery.document = GetConstellationNetworkMovesDocument;

useGetConstellationNetworkMovesQuery.getKey = (variables: GetConstellationNetworkMovesQueryVariables) => ['GetConstellationNetworkMoves', variables];


useGetConstellationNetworkMovesQuery.fetcher = (client: GraphQLClient, variables: GetConstellationNetworkMovesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetConstellationNetworkMovesQuery, GetConstellationNetworkMovesQueryVariables>(client, GetConstellationNetworkMovesDocument, variables, headers);
