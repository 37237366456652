import { Box, Typography } from '@mui/material'

import { CompanyAvatar, DataMissing, Map, Route, SubCard } from '@leaf/components'
import { color } from '@leaf/utilities'

import { CONTAINER_TABLE_HEIGHT } from '@/constants'
import type { ContractDetailsQueryReturnType } from '@/features/contract/contract-details.api'
import { useStore } from '@/store'

type LaneType = {
  destinationName?: string
  id: string
  leafMiles?: number
  originName?: string
  path: GeoJSON.LineString
  shipperId?: number
}

type ContractRoutesProps = {
  data?: ContractDetailsQueryReturnType['contractRoutes']
  show: boolean
}
const ContractDetailsRoutes = ({ data, show }: ContractRoutesProps) => {
  const user = useStore((state) => state.user)

  if (!show) {
    return null
  }
  const lanes: LaneType[] = []

  data?.forEach((d) => {
    d.route?.legs?.forEach((leg) => {
      if (leg.lane?.path) {
        lanes.push(leg.lane as LaneType)
      }
    })
  })

  const firstLane = lanes[0]
  if (!firstLane) {
    return (
      <Box
        sx={{
          height: CONTAINER_TABLE_HEIGHT,
          width: '100%',
        }}
      >
        <DataMissing />
      </Box>
    )
  }
  return (
    <>
      <SubCard xs={6}>
        {lanes.map((lane, i) => (
          <Box
            key={lane.originName}
            sx={{
              display: 'flex',
              marginBottom: i === lanes.length - 1 ? '48px' : '',
              position: 'relative',
            }}
          >
            <Route.RouteStyled
              $color={color.lane(i)}
              $position={i * 2 + 1}
              sx={{ marginLeft: '1.5em' }}
            />

            <Route.LaneStyled>
              <Typography variant='body2'>{lane?.originName}</Typography>

              <Box>
                <Route.DistanceStyled>
                  <Box gap={8} sx={{ display: 'flex' }}>
                    {lane?.leafMiles ? (
                      <Typography variant='caption'>{lane.leafMiles} miles</Typography>
                    ) : (
                      <Typography variant='caption'>Unknown miles</Typography>
                    )}
                  </Box>

                  <Route.HorizontalLineStyled />

                  <Box sx={{ marginLeft: '2', marginRight: '2' }}>
                    <CompanyAvatar id={user.companyId === lane.shipperId ? user.companyId : -1} />
                  </Box>
                </Route.DistanceStyled>
              </Box>

              <Typography variant='body2'>{lane.destinationName}</Typography>
            </Route.LaneStyled>
          </Box>
        ))}
      </SubCard>

      <SubCard sx={{ height: '600px', padding: '0 !important' }} xs={6}>
        <Map
          fitBounds={lanes.map((lane) => lane.path)}
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
        >
          {lanes.map((lane, i) => (
            <Route.MapLane
              color={color.lane(i)}
              geometry={lane.path}
              id={lane.id}
              key={lane.originName}
              position={i}
            />
          ))}
        </Map>
      </SubCard>
    </>
  )
}

export { ContractDetailsRoutes }
